import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import * as styles from "../feature/gallery/assets/css/gallery.module.scss"

const GalleryPage = () => {
  return (
    <Layout>
      <h2>おやつギャラリー</h2>
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi1.jpg"}
        quality={95}
        width={500}
        alt=""
      />
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi2.jpg"}
        quality={95}
        width={500}
        alt=""
      />
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi3.jpg"}
        quality={95}
        width={500}
        alt=""
      />
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi4.jpg"}
        quality={95}
        width={500}
        alt=""
      />
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi5.jpg"}
        quality={95}
        width={500}
        alt=""
      />
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi6.jpg"}
        quality={95}
        width={500}
        alt=""
      />
      <StaticImage
        className={styles.image}
        src={"../feature/gallery/assets/images/okashi7.jpg"}
        quality={95}
        width={500}
        alt=""
      />
    </Layout>
  )
}

export const Head = () => <Seo title="Gallery" />

export default GalleryPage
